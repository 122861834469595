import React from 'react'
import HubspotForm from 'react-hubspot-form'
import Layout from '../../../components/layout'

import {
	Container,
	Section,
} from '../../../components/layouts'

import {
	Subtitle,
	Paragraph,
	BlogAuthor,
	Link,
} from '../../../components/ui'

import powerPageHeading from '../../../assets/images/powerpage_heading.png'

import theme from '../../../theme/theme'

const {
	intro,
} = theme.sections

class IndexPage extends React.Component {

	componentDidMount() {
		window.scrollTo(0, -1500)
	}

	render () {
		return (
			<Layout>
				<Section id="intro" bg={intro.bg} paddingTop={'100px'}>
					<Container  >
						<img alt="Turning Millennials into Donors" src={powerPageHeading} width="100%"/>

						<Subtitle fontSize={'25px'} style={{ marginTop:'1em' }}>Actionable tips and methods for nonprofits looking to reach and engage millennials as active donors </Subtitle><br/>

						<Paragraph>
          Millennials--the generation rapidly changing the way we eat, work, exercise, shop, and now, the way we give. Characterized as the generation born between 1980 and 2000, millennials today range from the ages 18 to 38. With millennials now making up the largest portion of the population, it is imperative that nonprofits begin engaging and securing them as donors. But despite making up more than a quarter of the population, millennials account for only 11% of charitable giving.
							<br/><br/>This may come as a surprise, but millennials have really good intentions. Often referred to as the purpose-driven generation, millennials care about doing social good and yearn to make an impact on the world around them. But there’s a problem--according Blackbaud’s Next Generation of American Giving study, only 25% of millennials believe that monetary giving is the best way to make a difference with charitable organizations.
							<br/><br/>So how can you, as a nonprofit, turn well-meaning, “I’m-going-to-save-the-world” millennials into tangible donors? And even better--how can you turn them into recurring donors?
          In this guide, I cover seven approaches that will help your nonprofit both involve and capture the millennial generation.

							<ul>
								<li><Link onClick={this.linking} style={{ display: 'inline' }} href={'/blog/posts/MillennialsChapter1/'}>Chapter 1: 3 ways to get Millennials directly engaged with your organization</Link></li>
								<li><Link onClick={this.linking} style={{ display: 'inline' }} href={'/blog/posts/MillennialsChapter2/'}>Chapter 2: Generate Social Currency (aka Millennial Candy)</Link></li>
								<li><Link onClick={this.linking} style={{ display: 'inline' }} href={'/blog/posts/MillennialsChapter3/'}>Chapter 3: 4 Methods of Transparency that Millennials Love</Link></li>
								<li><Link onClick={this.linking} style={{ display: 'inline' }} href={'/blog/posts/MillennialsChapter4/'}>Chapter 4: How To Make Your Social Media Attractive and Effective</Link></li>
								<li><Link onClick={this.linking} style={{ display: 'inline' }} href={'/blog/posts/MillennialsChapter5/'}>Chapter 5: Turn the Tables By Getting Millennials To Fundraise For You</Link></li>
								<li><Link onClick={this.linking} style={{ display: 'inline' }} href={'/blog/posts/MillennialsChapter6/'}>Chapter 6: How to get Millennials to Pull the Trigger and Donate (in 2 minutes)</Link></li>
								<li>Chapter 7: Turning One-Time Donors into Lifetime Donors</li>
							</ul>

							<Subtitle fontSize={'25px'} style={{ marginTop:'1em' }} id="chapter7">Chapter 7: Turning One-Time Donors into Lifetime Donors</Subtitle>
					So you have some millennials on the hook--maybe they’ve given to your crowdfunding campaign, donated one time, or are part of a campus chapter. Think about your organization like a restaurant. Your donors have given you their business--they’ve “come to eat” once. But you don’t want them to just eat once. You want them to come back, to become a regular. The moment they sit down and choose to order the ball moves to your court--whether they come back and eat again is now contingent on your service. How well will you serve your customer?
							<ol>
								<li><strong>Make them feel special. </strong> I promise you that a generic thank you email with a donor’s name tacked onto it will NOT make any millennial feel special. Send them a letter,  give them a call ,or take a look at these <Link href="https://www.ignitedfundraising.com/twenty-ways-show-donorlove-every-day/" style={{ display:'inline' }}> 20 ways to show #donorlove</Link> from Ignited Fundraising. Millennials don’t want to feel like just another name on your donor list. Let them know that you value them. </li>
								<li><strong>Digestible touch points. </strong>Did you know that technology has actually changed the way we read and digest information? Numerous studies have shown that the human attention span has decreased. <i>People will not take the time to read long pieces of text that they did not seek out themselves</i>. It’s fine to send a newsletter, but don’t expect many millennials to take the time to read it. If you want to tell them something, do it in 180 characters or less.  </li>
								<li><strong>Don’t just email, text. </strong>Only about 18% of emails get opened compared to 98% of text messages. Try to get donors to opt into text messaging as often as you can. These touch points are much more likely to be opened and read by your donors. Be careful that don’t abuse this privilege, however. </li>
								<li><strong>Value their opinion. </strong>Millennials will be more invested in your organization if they believe that their opinions are being taken into account and they have some say in the organization’s initiatives. Now, I’m not saying that millennials should be informing decisions in your organization, but take the time to reach out and ask them for feedback. </li>
							</ol>
							<hr style={{ margin: '1em 0' }}/>

							<Subtitle fontSize={'30px'}>
							Looking for the full e-book?
							</Subtitle>
							<Paragraph
								fontSize={'21px'}
								marginRight={"2em"}
								fontWeight={'200'}>Fill out this form to receive our free guide <span style={{ fontStyle: 'italic' }}>Millennials To Donors</span></Paragraph>
							<HubspotForm
								id="reactHubspotForm"
								portalId="4829666"
								formId="1c650ab1-5fd3-4250-88c3-269180d71e88"
								loading={<div>{'Loading...'}</div>}
							/>

							<hr style={{ margin: '1em 0' }}/>
							And that’s the definitive guide to engaging and securing the Millennial generation.
							<br/>
							<br/>
              Now I’ll turn it over to you. What do you think of this guide? Is there anything I missed or you want to see covered? Please send me your thoughts at <Link style={{ display: 'inline' }} href="mailto:laura@flourishchange.com">laura@flourishchange.com</Link>.

						</Paragraph>
						<BlogAuthor author="laura"/>
					</Container>

				</Section>
			</Layout>
		)
	}
}

export default IndexPage
